/* globals Chartist */

(function () {
    'use strict';

    function main() {

        var page = wo$('#home');
        if (!page.length) return;

        const chartId = '#popular-phones-chart';
        const popularPhones = WhistleOut.readLookupData(wo$('#home-data'));

        var homeController = {

            init: function () {

                if (window.Chartist) {
                    page.find(chartId).onload = () => {

                        var chart = new Chartist.Bar(chartId,
                            {
                                labels: popularPhones.labels,
                                series: [popularPhones.values]
                            },
                            {
                                seriesBarDistance: 10,
                                reverseData: true,
                                horizontalBars: true,
                                axisY: { offset: 160 },
                                axisX: { offset: 0 }
                            });

                        chart.on('data', function (ctx) {
                            ctx.data.series = ctx.data.series.map(function (series) {
                                return series.map(function (value, valueIndex) {
                                    return {
                                        value: value,
                                        meta: popularPhones.urls[valueIndex]
                                    };
                                });
                            });
                        });
                    };
                }

                page.find(chartId).on('click', '.ct-bar', function () {
                    var url = wo$(this).attr('ct:meta');
                    location.href = url;
                });

                page.find('#lead-article').click(function () {
                    var url = wo$(this).attr('data-url');
                    location.href = url;
                });

                page.find('#popular-strip tr.hover-bg').click(function () {
                    var url = wo$(this).find('a').attr('href');
                    if (url) {
                        location.href = url;
                    }
                });

                WhistleOut.getAds('MobilePhones');
            }

        };

        homeController.init();
    };

    WhistleOut.initModule(import.meta.url, main);

}());
